<template>
  <el-upload
    class="avatar-uploader"
    action="#"
    :show-file-list="false"
    :auto-upload="false"
    :on-change="handleChange"
    accept="image/*"
    :multiple="multiple"
    :style="{
      height: '100%',
      widht: '100%',
    }"
  >
    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
  </el-upload>
</template>

<script>
  export default {
    props: ["value", "multiple", "expand"],

    data() {
      return {
        imageUrl: "",
      };
    },

    watch: {
      value: {
        immediate: true,
        handler(nv) {
          this.imageUrl = nv;
        },
      },
    },

    mounted() {
      if (this.expand) {
        this.handleDimension();
      }
    },

    destroyed() {
      this.imageUrl = "";
    },

    methods: {
      setImage() {
        console.log(this.value);
      },

      handleChange({ raw }) {
        const isImage = raw.type.includes("image");
        const isLt2M = raw.size / 1024 / 1024 < 2;

        if (!isImage) {
          return this.$message.error("Must be an image format!");
        }
        if (!isLt2M) {
          return this.$message.error("Size can not exceed 2MB!");
        }
        this.$nextTick(() => {
          this.imageUrl = URL.createObjectURL(raw);
        });
        this.$emit("input", raw);
      },

      handleDimension() {
        document.querySelector(".el-upload").style.width = "100%";
        document.querySelector(".el-upload").style.height = "350px";
        document.querySelector(".avatar-uploader").style.width = "100%";
        document.querySelector(".avatar-uploader").style.height = "350px";
      },
    },
  };
</script>

<style>
  .avatar-uploader .el-upload {
    display: grid;
    place-content: center;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 125px;
    height: 125px;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 125px;
    height: 125px;
    line-height: 125px;
  }
  .avatar {
    width: 100%;
    height: 100%;
    display: block;
  }
</style>
